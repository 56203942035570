import React, { ReactElement } from 'react'
import { i18n } from '../constants/i18n'
import Layout from '../components/layouts/main/Layout'
import SEO from '../components/util/seo/Seo'
import { graphql } from 'gatsby'
import Vim from '../components/shared/vim/Vim'

export interface LegalpageProps {
  pageContext: {
    [locale: string]: string
  }
  location: {
    pathname: string
  }
  data: {
    siteData: {
      frontmatter: {
        seoTitle: string
        seoDesc: string
      }
      html: string
    }
  }
}

const ImprintPage = ({
  pageContext: { locale },
  data: {
    siteData: {
      frontmatter: { seoTitle, seoDesc },
      html,
    },
  },
}: LegalpageProps): ReactElement => (
  <Layout locale={locale}>
    <SEO title={seoTitle} metaDescription={seoDesc} />
    <div className="container-normal pt-40">
      <Vim locale={locale} black>
        <>
          <h1>{i18n[locale].metaNavImprint}</h1>
          <div className="markdown whitespace-pre-line mt-10" dangerouslySetInnerHTML={{ __html: html }} />
        </>
      </Vim>
    </div>
  </Layout>
)

export default ImprintPage

export const query = graphql`
  query imprintPage($locale: String) {
    siteData: markdownRemark(frontmatter: { pageKey: { eq: "page_imprint" }, locale: { eq: $locale } }) {
      frontmatter {
        seoTitle
        seoDesc
      }
      html
    }
  }
`
